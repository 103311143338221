import { AppProvider, constants, i18Init, namespaces } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";
import { IConfigBrand } from "@sundae/gov-toolkit/dist/types/types/AppConfig";
import { Image, Link } from "@sundae/ui-toolkit";
import { EEX_PATHS } from "./types";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "optim"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { i18n } = useI18N("optim");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  const brand: Partial<IConfigBrand> = {
    images: {
      audit_pending: "/static/images/audit_pending.png",
      casting_a_vote: "/static/images/casting_a_vote.png",
      fatal_error: "/static/images/fatal_error.png",
      not_enough_tokens: "/static/images/not_enough_tokens.png",
      proposal_created: "/static/images/proposal_created.png",
    },
    classes: {
      buttons: {
        colored: "underline",
        primary: "underline",
      },
    },
  };

  return (
    <AppProvider
      brand={brand}
      componentProps={{
        layout: {
          legalUrl: EEX_PATHS.LEGAL,
        },
        header: {
          faqUrl: EEX_PATHS.FAQ,
          renderLogo: () => (
            <Link to={constants.paths.EPATHS.PROPOSAL_OVERVIEW}>
              <Image className="h-[20px] dark:hidden" src="/static/images/logo-black.svg" />
              <Image className="hidden h-[20px] dark:block" src="/static/images/logo-white.svg" />
            </Link>
          ),
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
